<template>
  <div v-loading="loadingData">
    <div class="app-modal__box" >
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{
              $t("message.flight", {
                m: $t("message.task"),
              })
            }}</p>
          <div>
            <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>

      <div class="app-modal__fulls p-5">
        <el-form ref="form" :model="form" :rules="rules" >

        <el-row :gutter="20">
          <el-col :span="18">
            <div class="wrap-all d-flex f-between f-wrap">
              <div class="w-100">
                <div class="gc-card rounded-sm mb-4 p-relative">
                  <div class="gc-card__header d-flex align-center px-3 py-4 font20">
                    <i class="fa-solid fa-file-lines mr-2"></i>
                    <div class="d-flex w-100 f-between flight__centered">
                      <span class="font-bold">
                        {{ $t("message.flight") }} № {{ form.id }} - {{ $t("message.waiting_to_complete") }}
                      </span>
                      <el-button size="small" class="btu-color">
                        Excel
                      </el-button>
                    </div>
                  </div>

                  <div class="gc-card__body px-3 py-4">
                    
                    <!-- end task-input-textarea -->

                    
                    <!-- end user-metca -->

                    
                    <!-- end data-task -->
                    <div class="task-input-textarea mt-5">

                      <table class="warehouse__table">
                            <thead>
                                <tr>
                                    <th class="w30p">
                                        <div class="custom-control custom-checkbox" :class="mode ? 'mode__sl1' : 'mode__sl2'">
                                            <input type="checkbox" class="custom-control-input" v-model="checkAll" id="checkAll" @change="handleCheckAllChange" />
                                            <label class="custom-control-label cursor-pointer" for="checkAll"></label>
                                        </div>
                                    </th>
                                    <th>Название</th>
                                    <th>ФИО</th>
                                    <th>Заявка</th>
                                    <th>Заказ</th>
                                    <th>Дата заявки</th>
                                    <th>Вес</th>
                                    <th>Габариты</th>
                                    <th>Цена</th>
                                    <th>Штрихкод</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="c in products" :key="c.id">
                                    <td>
                                        <div :class="mode ? 'mode__sl1' : 'mode__sl2'" class="custom-control custom-checkbox d-inline-block">
                                            <input type="checkbox" class="custom-control-input" v-model="selectedItems" :value="c" :id="'customCheck'+c.id" @change="handleCheckedItemsChange" />
                                            <label class="custom-control-label cursor-pointer" :for="'customCheck'+c.id"></label>
                                        </div>

                                    </td>
                                    <td>{{ c.name }}</td>
                                    <td>{{ c.fio }}</td>
                                    <td>21v</td>
                                    <td>{{ c.deal }}</td>
                                    <td>{{ c.date }}</td>
                                    <td>{{ c.weight }}</td>
                                    <td>{{ c.sizes }}</td>
                                    <td>{{ c.price }}</td>
                                    <td>{{ c.code }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="flight__return mt-4">
                            <el-button type="warning">
                                <i class="fa-solid fa-arrow-rotate-left"></i>
                                <span>Вернуть</span>
                            </el-button>
                        </div>

                    </div>
                    <!-- end task-input-textarea -->                    
                    <div class="task-check-tasks d-block" v-for="list in form.checkLists" :key="list.number">
                      <div class="mt-5 mb-4 d-flex align-center">
                        <i class="el-icon-tickets mr-2"></i>
                        <div class="d-flex w-100">
                          <span class="font-bold"> {{ list.name }}</span>
                          <div
                            class="change-form ml-auto pointer"
                            ref="showChangeContentBtn"
                            @click="deleteCheckList(list)"
                          >
                            {{ $t("message.delete") }}
                          </div>
                        </div>
                      </div>

                      <div>
                        <el-progress
                          :text-inside="true"
                          :stroke-width="18"
                          :percentage="list.percentage"
                          status="success"
                        ></el-progress>
                      </div>


                      <div class="item-checkbox-task" v-for="miniTask in list.miniTasks" :key="miniTask.number">
                        <div class="checkbox-task">
                          <input type="checkbox" id="vehicle1" @change="checkListPercentage(miniTask.checkListNumber)" name="vehicle1" v-model="miniTask.is_done" value="Bike">
                          <span class="checkbox-text ml-2">{{ miniTask.text }}</span>
                        </div>


                        <div class="checkbox-task-delet d-flex">
                          <i class="el-icon-delete" @click="deleteMiniTask(miniTask)"></i>  
                          <i class="el-icon-edit-outline" @click="updateMiniTask(miniTask)"></i>
                        </div>
                      </div>
                      <!-- end  item-checkbox-task-->

                      <button
                        class="my-button ml-2"
                        type="button"
                        style="width: auto"
                      >
                        <span @click="addMiniTask(list.number)"> {{ $t("message.add_item") }} </span>
                      </button>
                    </div>
                    
                    <div class="task-input-textarea mt-5" v-if="form.files && form.files.length > 0">
                      <span class="font-bold mb-2 d-block">
                        <i class="el-icon-notebook-2"></i> {{ $t("message.downloaded_files") }}
                      </span>
                      <div class="text-comentariya">
                          <div class="text-big ckas" v-for="file in form.files" :key="file.id">
                              <span class="p-4 d-block" >
                                {{ file.name }}
                              </span>
                              <div>
                              <el-button size="mini" type="primary" @click="downloadFile(file)"> <i class="el-icon-download" style="color: white" > {{ $t("message.download") }}</i></el-button>
                              <el-button size="mini" type="danger" @click="destroyFile(file)"> <i class="el-icon-delete" style="color: white"> </i> {{ $t("message.delete") }} </el-button>
                              </div>
                          </div>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="wrap-all d-flex f-between f-wrap">
              <div class="w-100">
                <div class="gc-card rounded-sm mb-4 p-relative">
                  <div class="gc-card__header d-flex align-center px-3 py-4">
                    <i class="fa-solid fa-gear mr-2"></i>
                    <div class="d-flex w-100">
                      <span class="font-bold"> {{ $t("message.information_flight") }}</span>
                    </div>
                  </div>
                  <div class="gc-card__body px-3 py-4">
                    <div class="itme-right-Buttons">
                      <span class="text-descr__label d-block">
                        {{ $t("message.add_to_card") }}
                      </span>
                      <div
                        class="button-style-my2 mb-4"
                        @click="participants = true"
                      >
                        <div class="d-flex flight__centered">
                            <i class="fa-solid fa-plane"></i>
                            <p>{{ $t("message.flight") }}</p>
                        </div>
                        <div>
                            <span>HY-232</span>
                        </div>
                      </div>
                      <div
                        class="button-style-my2 mb-4"
                        @click="participants = true"
                      >
                        <div class="d-flex flight__centered">
                            <i class="fa-solid fa-map-location-dot"></i>
                            <p>{{ $t("message.direction") }}</p>
                        </div>
                        <div>
                            <span>Нью‑Йорк — Ташкент</span>
                        </div>
                      </div>

                      <div
                        class="button-style-my2 mb-4"
                        @click="labels = true"
                      >
                        <div class="d-flex flight__centered">
                            <i class="fa-solid fa-warehouse"></i>
                            <p>{{ $t("message.warehouse") }}</p>
                        </div>
                        <div>
                            <span>Havvoex Brooklyn</span>
                        </div>
                      </div>
                      <div
                        class="button-style-my2"
                        @click="term = true"
                      >
                        <div class="d-flex flight__centered">
                            <i class="fa-solid fa-calendar-days"></i>
                            <p>{{ $t("message.date") }}</p>
                        </div>
                        <div>
                            <span>18.08.2022-18:50 > 20.08.2022-01:30</span>
                        </div>
                      </div>
                      <div
                        class="button-style-my2 mb-4"
                        @click="labels = true"
                      >
                        <div class="d-flex flight__centered">
                            <i class="fa-solid fa-boxes-stacked"></i>
                            <p>{{ $t("message.number_of_cargo") }}</p>
                        </div>
                        <div>
                            <span>20</span>
                        </div>
                      </div>
                      <div
                        class="button-style-my2 mb-4"
                        @click="labels = true"
                      >
                        <div class="d-flex flight__centered">
                            <i class="fa-solid fa-weight-hanging"></i>
                            <p>{{ $t("message.all_weight") }}</p>
                        </div>
                        <div>
                            <span>100 кг</span>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
                <div class="gc-card rounded-sm mb-4 p-relative">
                  <div class="gc-card__header d-flex align-center px-3 py-4">
                    <i class="fa-solid fa-users mr-2"></i>
                    <div class="d-flex w-100">
                      <span class="font-bold"> {{ $t("message.employee_information") }}</span>
                    </div>
                  </div>
                  <div class="gc-card__body px-3 py-4">
                    <div class="itme-right-Buttons">
                      
                      <div class="flight__detail mb-2">
                        <span class="text-descr__label">{{ $t("message.create_by") }}</span>
                        <span>Ибрагимов Сардор</span>
                      </div>
                      <div class="flight__detail mb-2">
                        <span class="text-descr__label">Администрация</span>
                        <span>Собиров Али</span>
                      </div>
                      <div class="flight__detail">
                        <span class="text-descr__label">{{ $t("message.warehouse") }}</span>
                        <span>Собиров Али</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
            
      </el-form>
      <div class="mt-5">
        <el-row class="mt-5">
          <el-button
            v-if="(form.finish_date && form.status === 4 && ((authUser.is_super_admin || (authUser.id === (form.user ? form.user.id : 0))) || authUser.control_user.task_control))"
            type="warning"
            @click="archivatingTask(form.id)"
            >{{ $t('message.add_to_completed_tasks') }}</el-button
          >
        </el-row>
      </div>

      </div>
      
    </div>

    <!-- the modals -->

    <el-dialog
      :title="$t('message.flight')"
      :visible.sync="participants"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerParcipants"
      @opened="drawerOpened('drawerParcipantsChild')"
      @closed="drawerClosed('drawerParcipantsChild')"
    >
      <Direction
        ref="drawerParcipantsChild"
        drawer="drawerParcipants"
        :participants="form.participants"
        :user_id="form.user ? form.user.id : null"
        @getCheckedUsers="updateParticipants"
        > </Direction>
    </el-dialog>
    <!-- end Participants -->

    <el-dialog
      title="Добавление список задач"
      :visible.sync="addingTask"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerCheckLists"
      @opened="drawerOpened('drawerCheckListsChild')"
      @closed="drawerClosed('drawerCheckListsChild')"
    >
      <AddingTask
        ref="drawerCheckListsChild"
        drawer="drawerCheckLists"
        @getNewCheckList="updateCheckList"
        > </AddingTask>
    </el-dialog>
    <!-- end AddingTask -->

     <el-dialog
      title="Добавить задачу"
      :visible.sync="addingMiniTask"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerCreateMiniTasks"
      @opened="drawerOpened('drawerCreateMiniTasksChild')"
      @closed="drawerClosed('drawerCreateMiniTasksChild')"
    >
      <CreateMiniTask
        :checkListNumber="checkListForMiniTask"
        ref="drawerCreateMiniTasksChild"
        drawer="drawerCreateMiniTasks"
        @getNewMiniTask="updateMiniTaskList"
        > </CreateMiniTask>
    </el-dialog>


    <el-dialog
      title="Изменить задачу"
      :visible.sync="updateMiniTaskDrawer"
      width="30%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerUpdateMiniTasks"
      @opened="drawerOpened('drawerUpdateMiniTasksChild')"
      @closed="drawerClosed('drawerUpdateMiniTasksChild')"
    >
      <UpdateMiniTask
        :model="selectedMiniTaskForUpdate"
        ref="drawerUpdateMiniTasksChild"
        drawer="drawerUpdateMiniTasks"
        @updateMiniTask="updateMiniTaskList"
        > </UpdateMiniTask>
    </el-dialog>


    <el-dialog
      title="Даты"
      :visible.sync="term"
      width="40%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerDates"
      @opened="drawerOpened('drawerDatesChild')"
      @closed="drawerClosed('drawerDatesChild')"
    >
      <Term
        :taskDates="taskDates"
        ref="drawerDatesChild"
        drawer="drawerDates"
        @getDates="updateDates"
        > </Term>
    </el-dialog>
    <!-- end AddingTask -->

    <el-dialog
      title="Метки"
      :visible.sync="labels"
      width="35%"
      :append-to-body="true"
      class="add-participants"
      ref="drawerLabels"
      @opened="drawerOpened('drawerLabelsChild')"
      @closed="drawerClosed('drawerLabelsChild')"
    >
      <Tags
        ref="drawerLabelsChild"
        drawer="drawerLabels"
        :selectedTaskLabels="form.labels"
        @getTaskLabels="updateTaskLabels"
        > </Tags>
    </el-dialog>
    <!-- end metka -->

    <el-dialog
      class="KP_modal"
      title="Удалить"
      :visible.sync="drawerDeleteTask"
      :append-to-body="true"
      width="30%"
      center
      ref="drawerDeleteTask"
      @opened="drawerOpened('deleteTaskComponent')"
      @closed="drawerClosed('deleteTaskComponent')"
    >

      <delete-task
        ref="TaskOfferComponent"
        :task_id="form.id"
        @deleteTaskClose="deleteTaskClose"
        drawer="drawerTaskOfferCreate"
      >
      </delete-task>
    </el-dialog>

    <div class="app-modal app-modal__full modal-color-bg">

        <el-drawer
          :with-header="false"
          :visible.sync="drawerCreateClient"
          ref="drawerCreateClient"
          size="70%"
          :append-to-body="true"
          class="body_scroll_70" 
          @opened="drawerOpened('drawerCreateClientChild')"
          @closed="drawerClosed('drawerCreateClientChild')"
        >
            <div>
                <create-client
                    ref="drawerCreateClientChild"
                    drawer="drawerCreateClient"
                >
                </create-client>
            </div>
        </el-drawer>
    
      
        <el-drawer
          size="80%"
          :append-to-body="true"
          class="body_scroll_80 mt-00" 
          :visible.sync="drawerCreateDeal"
          :fullscreen="true"
          ref="drawerDealCreate"
          custom-class="ge"
          @opened="drawerOpened('drawerDealCreateChild')"
          @closed="drawerClosed('drawerDealCreateChild')"
          >
          <div
              @click="closeAddModel()"
              class="
              close-modal-stick
              d-flex
              align-center
              p-fixed
              text-white
              p-2
              font-bold
              pointer
              transition
              "
          >
              <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
              </div>
              <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <CreateDeal
              ref="drawerDealCreateChild"
              :dealCreate="dealCreate"
              drawer="drawerDealCreate"
          >
          </CreateDeal>
        </el-drawer>
    
    </div>
  </div>
</template>        
<script>
import Participants from "./participants";
import Direction from "./direction";

import AddingTask from "./addingTask";
import CreateMiniTask from "./addingMiniTaskForCheckList";
import UpdateMiniTask from "./updateMiniTaskForCheckList";
import DeleteTask from "./delete-task";
import Term from "./term";
import Tags from "./tags";
import selectDeal from "@/components/inventory/select-deal";
import selectClient from "@/components/selects/select-client";
import CreateClient from "@/views/clients/components/crm-create";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import task from "@/utils/mixins/views/task";

export default {
  name: 'TaskUpdate',
  mixins: [form, drawer, show, task],
  components: {
    Participants,
    AddingTask,
    Term,
    Tags,
    CreateMiniTask,
    UpdateMiniTask,
    selectDeal,
    Direction,
    selectClient,
    DeleteTask,
    CreateDeal: () => import('@/views/deals/components/add-modal'),
    CreateClient,
  },
  props: {
      board_id:{
        default: null
      }
    },
  data() {
    return {
      products: [
        {
            id: 1,
            name: 'MITSUBISHI PENCIL',
            weight: '100',
            sizes: '3x8',
            price: '80',
            code: '49 02778 140673',
            fio: 'Doston Abdullayev',
            deal: '1',
            date: '21.01.2022'
        },
        {
            id: 2,
            name: 'ZARA SHOES',
            weight: '100',
            sizes: '4x8',
            price: '90',
            code: '49 02778 140673',
            fio: 'Doston Abdullayev',
            deal: '1',
            date: '21.01.2022'
        },
        {
            id: 3,
            name: 'NIKE JORDAN',
            weight: '100',
            sizes: '6x8',
            price: '180',
            code: '49 02778 140673',
            fio: 'Doston Abdullayev',
            deal: '1',
            date: '21.01.2022'
        }
      ],
      taskToChecking: false,
      drawerCreateClient: false,
      drawerCreateDeal: false,
      dealCreate: true,
      comment: "",
      client_id: null,
      participants: false,
      labels: false,
      term: false,
      addingMiniTask: false,
      updateMiniTaskDrawer: false,
      checkListForMiniTask: null,
      selectedMiniTaskForUpdate: {},
      deal_id: null,
      finishDateCheck: false,
      inputTask: "",
      value: "",
      value1: "",
      image: "",
      addingTask: false,
      fileList: [],
      updateFile: [],
      taskDates: [],
      finish_date: '',
      drawerDeleteTask: false,
      taskPercentage: 0,
      miniTasksCount: 0,
      fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
    };
  },
  computed: {
      ...mapGetters({
          authUser: "auth/user",
          rules: "tasks/rules",
          model: "tasks/model",
          columns: "tasks/columns",
          mode: "MODE"
      }),
  },
  watch: {
      finishDateCheck(val) {
        if(val){
          if(this.finish_date){
            this.form.finish_date = this.finish_date;
          }else{
             this.form.finish_date = this.formatDate(new Date());
          }
        }else{
          this.form.finish_date = ''
        }
      },
      form: {
          handler: async function(newVal, oldVal) {
              this.taskPercentage = 0;
              let completed = 0;
              let uncompleted = 0;
              this.form.checkLists.forEach(check_list => {
                check_list.miniTasks.forEach(mini_task => {
                  if(mini_task.is_done){
                    completed += 1
                  }else{
                    uncompleted += 1
                  }
                });
              });
              this.miniTasksCount = completed + uncompleted;
              if((completed + uncompleted) === 0){
                this.taskPercentage = 0;
              }else{
                this.taskPercentage = 100*completed/(completed + uncompleted);
              }
              if(this.taskPercentage === 100 && this.miniTasksCount > 0){
                this.form.status = 4;
              }
              if(this.taskPercentage < 100 && this.miniTasksCount > 0){
                this.form.status = 1;
              }
              if(this.form.status === 4){
                this.taskToChecking = true;
              }
          },
          deep: true,
          immediate: true
      },
      taskToChecking: {
          handler: async function(newVal, oldVal) {
             if(newVal){
               this.form.status = 4;
             }else{
               this.form.status = 1;
             }
          },
          deep: true,
          immediate: true
      },
  },
  methods: {
    ...mapActions({
        save: "tasks/update",
        show: "tasks/show",
        saveFiles: "tasks/storeFiles",
        empty: "tasks/empty",
        downloadFileFromStorage: "tasks/downloadFile",
        deleteFile: "tasks/deleteFile",
        archivateTask: "tasks/archivateTask",
        updateDealTasks: "deals/dealTasks",
        updateDealsList: "boards/index",
    }),
    archivatingTask(task_id) {
      this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.archivateTask(task_id).then(() => {
          this.close(true);
          this.parent().listChanged();

          });
          this.$message({
            type: "success",
            message: this.$t('message.operation_completed')
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
    },
    deleteTaskClose(val) {
      this.drawerDeleteTask = false;
      if(val){
        this.close();
        this.parent().listChanged();
      }
    },

    checkListPercentage(checkListNumber){
      if(this.form.checkLists && this.form.checkLists.length > 0){
        let allCount = this.form.checkLists[checkListNumber-1].miniTasks.length;
        let selectedCount = this.form.checkLists[checkListNumber-1].miniTasks.filter(task => task.is_done === true);
        selectedCount = selectedCount.length;
        let percentage = 0;
        if(allCount > 0){
          percentage = selectedCount*100/allCount;
        }
        this.form.checkLists[checkListNumber-1].percentage =  Math.round(percentage);
        this.form.checkLists = JSON.parse(JSON.stringify(this.form.checkLists))

      }
    },
    fetchData() {
      if (!this.loadingData) {  
          this.loadingData=true        
          this.show(this.selectedItem.id).then(res => { 
              this.form.checkLists.map((list, index) => {
                  list.number = index + 1;
                  let checked = list.miniTasks.filter(item => item.is_done === true).length;
                  let count = list.miniTasks.length;
                  if(count > 0){
                    list.percentage = Math.round(checked * 100/count);
                  }else{
                    list.percentage = 0;
                  }
                });
              
              this.form.checkLists.map((list, index) => list.miniTasks.map((task ,key) => {
                task.checkListNumber = list.number;
                task.number = key + 1;
              }));
              if(this.form.finish_date){
                this.finish_date = this.form.finish_date;
                this.finishDateCheck = true;
              }
              this.form.comments.map((item, index) => item.number = index + 1);
              this.client_id = this.form.client_id;
              this.taskDates.push(this.form.begin_date);
              this.taskDates.push(this.form.end_date);

              this.loadingData = false              
          }).catch(err => {
              this.loadingData = false
          });
      }
    },
    downloadFile(file) {
      let data = { task_id: this.form.id, file: file.name };
      this.downloadFileFromStorage(data)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file.name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },

    destroyFile(file){
      if(file.id){
        this.deleteFile({task_id: this.form.id, file: file.name})
          .then(res => {
            this.form.files = this.form.files.filter(item => item.id != file.id);
          });
      }
    },
   
  },
};
</script>
<style>
  .task_in_process_1 {
    background-color: rgb(255, 102, 0);
    color: white;
  }
  .task_for_check {
    background-color: rgb(50, 127, 226);
    color: white;

  }
  .task_done {
    background-color: rgb(92, 196, 92);
    color: white;
  }
  .ckas{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
  }
  .ckas span{
    font-size: 13px;
  }
  .wu-100 .el-upload{
    width: 100%;
  }
  .text-contents{
    min-height: 33px;
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 100%;
    font-size: inherit;
    color: #606266;
    background-color: #FFF;
    background-image: none;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  }
  .big-text-contents{
    display: flex;
    align-items: center;
    margin: 0 0 20px 6px;
  }
    .big-text-contents div i{
      font-size: 24px;
      padding: 5px;
    }
    .update-i {
      color: #2775a8;
    }
    .update-i {
      color: #2775a8;
    }
</style>
