<template>
  <div class="modal-add-task-participants" v-loading="loadingData">
    <div>
      <el-form ref="form" :model="form">
        <div class="app-form__group mb-4">
          <el-input
            :placeholder="$t('message.search')"
            v-model="input"
            size="medium"
          ></el-input>
        </div>
      </el-form>
    </div>
    <ul>
      <li class="metka mb-2" v-for="label in labels" :key="label.id" >
          <div @click="checkedLabels(label)" class="flex1">
            <el-radio :id="'radio_'+label.id" :ref="'radioCheck_' + label.id" :class="label.color ? label.color.name : 'color_1'" v-model="label.checked" :label="label.id">
              <i class="el-icon-check"></i>
            </el-radio>
          </div>
          <div class="post"> {{ label.name }} </div>
          <i class="el-icon-edit" @click="update(label)"></i>
          <i class="el-icon-delete c_red" @click="destroy(label)"></i>
      </li>
    </ul>

    <div>
        <el-button size="small"  @click="metkaColor = true" class="w-100">{{ $t('message.create_new_label') }}</el-button>
    </div>

    <div class="modal-footer-buts">
      <div class="d-flex justify-center">
        <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
        ></crm-store-update-close>
      </div>
    </div>


    <el-dialog
        :title="$t('message.create_new_label')"
        :visible.sync="metkaColor"
        width="30%"
        :append-to-body="true"
        class="add-participants"
        @opened="drawerOpened('drawerDatesChild')"
        @closed="drawerClosed('drawerDatesChild')"
        >
        <MetkaColor
        ref="drawerDatesChild"
        drawer="drawerDates"
        > </MetkaColor>

    </el-dialog>
    <!-- end metka -->

    <el-dialog
        :title="$t('message.update_label')"
        :visible.sync="updateMetkaColor"
        width="30%"
        :append-to-body="true"
        class="add-participants"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
        >
        <UpdateMetkaColor
        :selectedItem="selected"
        ref="drawerUpdateChild"
        drawer="drawerUpdate"
        > </UpdateMetkaColor>

    </el-dialog>
    <!-- end metka -->

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

import MetkaColor from "./metkaColor";
import UpdateMetkaColor from "./updateMetkaColor";
export default {
  mixins: [form, drawer],
  
  components: {
      MetkaColor,
      UpdateMetkaColor
  },
  props: {
    selectedTaskLabels:{
      default: Array
    }
  },
  data() {
    return {
      radio: '',
      radio1: '',
      participants: false,
      input: '',
      checked: false,
      metkaColor: false,
      updateMetkaColor: false,
      color1: "#409EFF",
      reloadList: false,
      labels: [],
      selected: '',
      firstEnter: false,
      toggle: false,
      loadingData: false,
    };
  },

  computed: {
      ...mapGetters({
          allLabels: "labels/inventory",
      }),
  },
  methods: {
    ...mapActions({
        updateInventory: "labels/inventory",
        delete: "labels/destroy",
    }),
    afterOpen(){
        this.fetchData();
    },
    listChanged() {
        this.reloadList = true;
    },
    fetchData(){
      this.loadingData = true;
      this.updateInventory()
          .then(res => {
            this.labels = [];
            let items = JSON.parse(JSON.stringify(this.allLabels))
            items.forEach(label => {
              if(this.selectedTaskLabels.some(item => item.id == label.id)){
                label.checked = label.id;
                label.toggle = 1;
              }else{
                label.checked = '';
                label.toggle = 2;
              }
              this.labels.push(label);

            });
            this.loadingData = false;

          });
    },
    checkedLabels(label) {
      let id = '';
      if(this.firstEnter){
        if(label.toggle === 1){
          label.toggle = 2;
          label.checked = '';
          id = "radio_" + label.id;
          var element = document.getElementById(id);
          element.classList.remove("is-checked");
        }else{
          label.toggle = 1;   
        }

        this.firstEnter = false
      }else{

        this.firstEnter = true
      }
    },

    submit(close){
      this.form.taskLabels = this.labels.filter(label => label.checked)
      this.$emit("getTaskLabels", this.form.taskLabels);
      if (close) this.close();
    },
    update(label){
      this.selected = label;
      setTimeout(() => {
        this.updateMetkaColor = true;
      }, 500);
    },
    drawerClosed(ref) {
        if (this.$refs[ref]) {
            this.$refs[ref].closed()
        }
        if (this.reloadList === true) {
            
            this.fetchData();
            this.reloadList = false;
        }
        if (_.isFunction(this.empty)) {
            this.empty()
        }
    },
    drawerOpened(ref) {        
        if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
                this.$refs[ref].opened()
            }
        }
    },
    closeDrawer(drawer) {    
        this.metkaColor = false;
        this.updateMetkaColor = false;
        if (this.$refs[drawer]) {
        }
        
    },
    destroy(model) {
            this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t('message.warning'), {
                confirmButtonText: this.$t('message.yes'),
                cancelButtonText: this.$t('message.no'),
                type: "warning"
              }
            )
            .then(() => {
              let labels = this.parent().form.labels.filter(item => item.id != model.id);
              this.parent().form.labels = labels;
              this.delete(model.id)
                  .then(res => {
                      this.$alert(res);
                      this.fetchData()
                  })
                  .catch(err => {
                    this.$alert(err);
                  })
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
    },

    afterLeave(){
      this.labels = [];
      this.form = {};
    }
  },

};
</script>

<style>
</style>
